import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

export default function AboutPage() {
  return (
    <div className="flex flex-col px-5 h-full">
      <a
        href="https://github.com/isaurav0/memecraft"
        className="flex items-center"
        target="_blank"
      >
        <FontAwesomeIcon icon={faGithub} className="fa-2x" />
        <div className="ml-5">isaurav0/memecraft</div>
      </a>

      <div className="mt-5 text-gray-400 text-sm">
        This project is open source and available on GitHub. Feel free to
        contribute!
      </div>
    </div>
  );
}
