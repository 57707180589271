import { fabric } from 'fabric';

/* 

    1. While adding any element on Canvas, one copy should be saved in layers array.

*/

function addImageToCanvas(editor, myImg) {

  const img = myImg.set({
    left: 0,
    objectCaching: false,
    top: 150
  });
  img.scaleToWidth(760);
  img.scaleToHeight(450);
  editor.add(img);
}

function addImageFromUrl(editor, url, try_more = true) {

  const image_proxy = url => {
    return `/.netlify/functions/downloader?url=${url}`;
  };

  return fabric.Image.fromURL(url, (myImg) => {
    if (myImg._element === null && try_more)
      return addImageFromUrl(editor, image_proxy(url), false);
    addImageToCanvas(editor, myImg);
  }, { crossOrigin: 'anonymous' });
}


function addImageFromFile(editor, file) {
  if (!file || editor === '')
    return;

  function sendDataToEditor(data) {
    addImageToCanvas(editor, data);
  }

  const reader = new FileReader();
  reader.onload = function (f) {
    const data = f.target.result;
    fabric.Image.fromURL(data, sendDataToEditor);
  };
  reader.readAsDataURL(file);
}



export { addImageFromUrl, addImageFromFile };
