import React from 'react';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FileDrop(props) {

    let baseClassname = "flex flex-col items-center p-10 text-sm text-gray-300 border-dotted border-gray-300 border-2 rounded-lg cursor-pointer hover:border-blue-500 hover:text-gray-300 hover:bg-slate-700";
    let className;
    return (
        <Dropzone
            onDrop={props?.onDrop}
            minSize={1024}
            maxSize={3072000}
        >
            {({
                getRootProps,
                getInputProps,
                isDragActive,
            }) => {

                if (isDragActive)
                {
                    className = baseClassname + " border-blue-500"
                }
                else
                {
                    className = baseClassname
                }

                return (
                    <div
                        {...getRootProps()}
                        className={className}
                    >
                        <input {...getInputProps()} />
                        {props?.icon && <span><FontAwesomeIcon icon={props.icon} /> </span>}
                        <p className='text-center'>{props.text}</p>
                    </div>
                );
            }}
        </Dropzone>
    )
}