import { fabric } from 'fabric';

/* 

    1. While adding any element on Canvas, one copy should be saved in layers array.

*/

export function addTextToCanvas(editor, text) {

    if (!editor) return
    const textBox = new fabric.Textbox(text || 'Type Here....', {
        fill: "black",
        editable: true,
        objectCaching: false,
        width: 1000,
        fontSize: 100,
        scaleX: 0.5,
        scaleY: 0.5,
        top: 10,
        left: 10,
        textAlign: "center",
        fontFamily: "Arial"
    });
    editor.add(textBox);
    editor.setActiveObject(textBox);

}