import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import TextLeftBar from "./TextLeftBar";
import ImagesLeftBar from "./ImagesLeftBar";
import LayersLeftBar from "./LayersLeftBar";
import DraftsLeftBar from "./DraftsLeftBar";
import AboutPage from "./AboutPage";

export default function ExpandedLeftBar({ title, toggleActive, editor }) {
  return (
    <div className="bg-slate-800 w-80 ">
      <div className="flex text-gray-200 border-b border-slate-700 py-1 items-center mb-5">
        <div className="m-auto uppercase text-sm">{title}</div>
        <div className="text-center mr-2 cursor-pointer">
          <FontAwesomeIcon
            icon={faChevronLeft}
            onClick={(e) => toggleActive()}
          />
        </div>
      </div>
      {title === "Text" && <TextLeftBar editor={editor} />}
      {title === "Images" && <ImagesLeftBar editor={editor} />}
      {title === "Layers" && <LayersLeftBar editor={editor} />}
      {title === "Drafts" && <DraftsLeftBar editor={editor} />}
      {title === "About" && <AboutPage />}
    </div>
  );
}
