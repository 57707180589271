import React, { useEffect, useMemo, useCallback, useState } from 'react';

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTextHeight, faQuestion, faImage, faFolderPlus, faLayerGroup, faFile } from '@fortawesome/free-solid-svg-icons';

import ExpandedLeftBar from './ExpandedLeftBar';

export default function LeftSideBar(props) {

    const [showDetailsBar, setShowDetailsBar] = useState(false);
    const [sideBarOptions, setSideBarOptions] = useState([
        {
            title: 'Images',
            icon: faImage,
            active: false
        },
        {
            title: 'Text',
            icon: faTextHeight,
            active: false
        },
        {
            title: 'Layers',
            icon: faLayerGroup,
            active: false
        },
        {
            title: 'Templates',
            icon: faFolderPlus,
            active: false
        },
        {
            title: 'Drafts',
            icon: faFile,
            active: false
        },
        {
            title: 'About',
            icon: faQuestion,
            active: false,
            isBottom: true
        }
    ]);

    const getActiveOption = useMemo(() => {
        return sideBarOptions
            .filter(_ => _.active)
            .reduce((acc, _) => _.title, '') || '';
    }, [sideBarOptions]);

    if (!props.editor) return <></>;

    const toggleActive = title => {
        setSideBarOptions(sideBarOptions.map(option => {
            option.active = (option.title === title) ? !option.active : false;
            return option;
        }));
    };

    const Option = (props) => {
        let className = "flex-col flex text-center text-xs ml-1 px-1 py-4 my-3 cursor-pointer";
        className += props?.active ? " bg-slate-800 rounded-l-full"
            : " bg-slate-600 hover:bg-gray-500 rounded-full"

        className += props?.isBottom ? " mt-auto mb-12" : '';
        return (
            <div className={className} onClick={e => toggleActive(props?.title)}>
                {props?.icon &&
                    <div className="text-center">
                        <FontAwesomeIcon icon={props.icon} />
                    </div>
                }
                <div className="text-center text-xs">{props?.title}</div>
            </div>
        )
    }

    return (
        < aside className="bg-slate-700 hidden  md:flex" >

            {/* Icon Bar */}
            < div className='bg-slate-600 flex flex-col pt-6' onClick={e => setShowDetailsBar(!showDetailsBar)} >
                {
                    sideBarOptions.map((_, k) => (
                        <Option key={k} {..._} />
                    ))
                }
            </ div>

            {/* Icon Details Slide Bar */}
            {getActiveOption && <ExpandedLeftBar title={getActiveOption} toggleActive={toggleActive} editor={props.editor} />}

        </aside >
    )
}