export const loadDraftFromFileDrop = (editor, file) => {
    if (!file || editor === '')
        return;
    const reader = new FileReader();
    reader.onload = function (f) {
        const data = f.target.result;
        editor.loadFromJSON(JSON.parse(data));
    };
    reader.readAsText(file);
};


function saveProject(editor, projectName) {
    const originalData = JSON.stringify(editor);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([JSON.stringify(originalData, null, 2)], {
        type: 'text/plain'
    }));
    a.setAttribute('download', projectName + '.memecraft');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

function saveAsImage(editor) {

    const getClippingBoundary = () => {
        let startX = editor.width;
        let startY = editor.height;

        return { startX: 0, startY: 0, endX: startX, endY: startY };
    };

    const { startX, endX, startY, endY } = getClippingBoundary();

    let image = editor.toDataURL({
        left: startX,
        top: startY,
        width: endX,
        height: endY
    });

    image = editor.toDataURL();

    const link = document.createElement('a');
    link.href = image;
    link.download = 'image.png';
    link.click();
}

export { saveProject, saveAsImage }