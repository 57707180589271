import React from 'react';


export default function Input({
    className,
    placeholder,
    type,
    value,
    onChange,
    ...props
}) {

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            props?.onEnter(e);
        }
        props?.onKeyDown && props.onKeyDown(e);
    }

    return (
        <input
            className={className || "button text-gray-800 text-xs bg-blue-100 p-2 focus:outline-dotted focus:outline-1 focus:outline-blue-100 rounded mt-5"}
            placeholder={placeholder || 'Enter text here'}
            type={type || 'text'}
            onKeyDown={handleKeyDown}
            value={value || ''}
            onChange={onChange}
            {...props}
        />
    )
}