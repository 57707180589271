import React, { useState } from 'react';
import { addImageFromFile, addImageFromUrl } from '../../utils/image';
import FileDrop from '../Utilities/FileDrop';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import Input from '../Utilities/Input';
import classNames from 'classnames';


export default function ImagesLeftBar({ editor }) {

  const [imageUrl, setImageURL] = useState('');

  const handleDrop = (e) => {
    addImageFromFile(editor, e[0]);
  };

  return (
    <div className='flex flex-col px-5 '>

      <FileDrop
        onDrop={handleDrop}
        text="Drop and drop or click to upload image"
        icon={faImage}
      />

      <p className='text-xs m-5 text-center text-gray-500'>
        Get started by uploading image to this project.
        You can either upload image by dragging image on
        the dropzone or by pasting image url.
      </p>

      <Input
        className={
          classNames(
            'button',
            'text-gray-800',
            'text-xs',
            'bg-blue-100',
            'p-2',
            'focus:outline-dotted',
            'focus:outline-1',
            'focus:outline-blue-100',
            'rounded mt-5',
            'mb-3'
          )
        }
        placeholder='Paste Image URL (eg: https://image.com/image.png)'
        type="url"
        onEnter={() => {
          addImageFromUrl(editor, imageUrl);
          setImageURL('');
        }}
        value={imageUrl}
        onChange={e => setImageURL(e.target.value)}
      />

      <button
        className='button text-sm px-16 py-1 text-gray-900 font-bold rounded bg-green-400 hover:bg-green-300'
        onClick={e => {
          addImageFromUrl(editor, imageUrl);
          setImageURL('');
        }}
      >
        Import From URL
      </button>

    </div>
  );
}