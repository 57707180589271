function deleteActiveObject(editor) {
    const activeObject = editor.getActiveObject();
    if (activeObject && activeObject.isEditing)
    {
        return;
    }
    editor.remove(activeObject);
}

function moveActiveObject(editor, key) {

    const MOVE_STEP = 5;

    switch (key)
    {
        case 'up':
            editor.getActiveObject().top -= MOVE_STEP;
            break;
        case 'down':
            editor.getActiveObject().top += MOVE_STEP;
            break;
        case 'left':
            editor.getActiveObject().left -= MOVE_STEP;
            break;
        case 'right':
            editor.getActiveObject().left += MOVE_STEP;
            break;
        default:
            break;
    }
    editor.renderAll();
}

export { deleteActiveObject, moveActiveObject }