import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// simple button that remembers its state [ on or off]
export const ButtonSwitch = (props) => {
    return !props?.state ? (
        <button
            className={"bg-slate-700 px-4 py-3 rounded w-fit uppercase text-sm hover:bg-blue-600 " + props.className}
            onClick={() => props?.onClick ? props?.onClick() : null}
        >
            {props.value || ' '}
            {props.icon ? <FontAwesomeIcon icon={props.icon} /> : null}
        </button>
    ) : (
        <button
            className={"bg-blue-600 px-4 py-3 rounded w-fit uppercase text-sm hover:bg-blue-700 " + props?.className}
            onClick={() => props?.onClick ? props?.onClick() : null}
        >
            {props.value || ' '}
            {props.icon ? <FontAwesomeIcon icon={props.icon} /> : null}
        </button>
    )
};