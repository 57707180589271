import React from 'react';
import logo from '../../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faFileExport, faUpload } from '@fortawesome/free-solid-svg-icons';
import { saveAsImage, saveProject } from '../../utils/project';

export default function Navbar({ projectName, setProjectName, editor }) {

    if (!editor) return (<></>)

    return (
        <nav className=" bg-slate-800 flex p-1">
            <div className="flex-grow flex items-center">
                <img src={logo} className="w-6 h-6 ml-3" alt="logo" />
                <div className=" text-blue-200 ml-5 uppercase font-semibold text-sm">
                    Memecraft
                </div>

                <FontAwesomeIcon className='ml-3 text-xs text-gray-300' icon={faChevronRight} />

                <input
                    className="text-gray-400 ml-5 text-sm bg-slate-800 hover:bg-slate-700 p-1 rounded outline-none focus:bg-slate-700 w-44"
                    value={projectName}
                    onChange={e => setProjectName(e.target.value)}
                    onBlur={e => {
                        if (projectName.length === 0)
                            setProjectName('Untitled Project')
                    }}
                />
            </div>

            <div className="flex flex-row items-center mr-2">

                <button
                    className='button text-sm px-5 py-1 mr-3 text-gray-100 font-bold rounded bg-blue-600 hover:bg-blue-500'
                    onClick={() => saveProject(editor, projectName)}
                    title="Draft can be loaded back into this application and edited."
                >
                    Save As Draft &nbsp;&nbsp; <FontAwesomeIcon icon={faFileExport} />
                </button>

                <button
                    className='button text-sm px-16 py-1 text-gray-900 font-bold rounded bg-green-400 hover:bg-green-300'
                    onClick={e => saveAsImage(editor)}
                >
                    Export As Image &nbsp;&nbsp; <FontAwesomeIcon icon={faUpload} />
                </button>
            </div>
        </nav>
    )
}