import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faLock, faTrash, faTextHeight, faUnlock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import './LayersLeftBar.css';

export default function LayersLeftBar({ editor }) {

    const [canvasObjects, setCanvasObjects] = useState(editor.getObjects());

    if (!canvasObjects) return null;

    editor.on({
        'object:added': () => setCanvasObjects(editor.getObjects()),
        'object:removed': () => setCanvasObjects(editor.getObjects()),
    });

    const Object = ({ object }) => {

        let icon, title;

        if (!object) return <></>;

        if (object.type === 'image')
        {
            icon = faImage;
            title = "Image"
        }
        else if (object.type === 'textbox')
        {
            icon = faTextHeight;
            title = object.text;
        }

        return (
            <div
                className='py-3 px-2  hover:bg-gray-500 hover:cursor-pointer rounded-sm flex layer-object'
                onClick={() => {
                    editor.setActiveObject(object);
                    editor.renderAll();
                }}
            >
                <div className='flex-1'>
                    {icon && <FontAwesomeIcon icon={icon} />} &nbsp; {title}
                </div>
                <div className='options'>
                    <FontAwesomeIcon
                        icon={object.lockMovementX ? faLock : faLockOpen}
                        className="opacity-30 hover:opacity-100"
                        onClick={(e) => {
                            editor.getObjects().map(obj => {
                                if (obj === object)
                                {
                                    obj.lockMovementX = !obj.lockMovementX;
                                    obj.lockMovementY = !obj.lockMovementY;
                                    obj.lockRotation = !obj.lockRotation;
                                    obj.lockScalingX = !obj.lockScalingX;
                                    obj.lockScalingY = !obj.lockScalingY;
                                    obj.lockUniScaling = !obj.lockUniScaling;
                                    obj.lockSkewingX = !obj.lockSkewingX;
                                    obj.lockSkewingY = !obj.lockSkewingY;
                                    obj.lockScalingFlip = !obj.lockScalingFlip;
                                    obj.lockShearX = !obj.lockShearX;
                                    obj.lockShearY = !obj.lockShearY;
                                    obj.hasControls = !obj.hasControls;
                                    obj.editable = !obj.editable;
                                    obj.selectable = !obj.selectable;
                                    obj.evented = !obj.evented;
                                }
                                return obj
                            })
                            editor.renderAll();
                            setCanvasObjects(editor.getObjects());
                        }}
                    />
                    <FontAwesomeIcon icon={faTrash}
                        className="ml-3 opacity-30 hover:opacity-100"
                        onClick={(e) => {
                            editor.remove(object);
                            editor.discardActiveObject();
                            editor.renderAll();
                            e.stopPropagation();
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className='flex flex-col px-1'>
            {canvasObjects.length === 0 &&
                <p className='text-xs m-5 text-center text-gray-500'>
                    View and manipulate all the layers in your project. You can delete, lock and manage layer grouping here.
                </p>
            }
            {canvasObjects?.map((obj, index) => {
                return <Object key={index} object={obj} />
            })}
        </div>
    )
}