/* Library Stuffs */
import React, { useEffect, useState } from 'react';

/* Styling */
import './App.css';

/* Custom Components */
import LeftSideBar from './components/Sidebar/LeftSideBar';
import RightSideBar from './components/Sidebar/RIghtSideBar';
import Canvas from './components/Playground/Canvas';
import Navbar from './components/Navbar/Navbar';
import { registerEvents } from './lib/Events';

import { initCanvas, boundary } from './utils/canvas';


function App() {

  const [projectName, setProjectName] = useState('Untitled Project');

  const [canvasOptions] = useState(
    {
      width: 760,
      height: 700,
      backgroundColor: "white",
      preserveObjectStacking: true
    }
  );

  const [canvas, setCanvas] = useState('');


  useEffect(() => {

    if (canvas)
    {
      registerEvents(canvas);
    } else
      setCanvas(initCanvas(canvasOptions));

  }, [canvas, canvasOptions]);

  return (
    <div className="App flex flex-col text-white h-screen overflow-hidden">
      <Navbar
        projectName={projectName}
        setProjectName={setProjectName}
        editor={canvas}
      />

      <div className="bg-slate-700 flex flex-col flex-grow justify-between md:flex-row">

        {/* Left Side Bar */}
        <LeftSideBar editor={canvas} />

        {/* Canvas */}
        <Canvas />

        {/* Right Side Bar */}
        {<RightSideBar editor={canvas} />}

      </div>

    </div >
  );
}

export default App;