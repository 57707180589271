import React, { useState } from 'react';
import FileDrop from '../Utilities/FileDrop';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { loadDraftFromFileDrop } from '../../utils/project';


export default function DraftsLeftBar({ editor }) {

    const [imageUrl, setImageURL] = useState('');

    const handleDrop = (e) => {
        loadDraftFromFileDrop(editor, e[0]);
    };

    const availableDrafts = [
        {
            title: 'Keep your secrets',
            image: 'https://indianmemetemplates.com/wp-content/uploads/Alright-then-keep-your-secrets-1024x846.jpg',
        },
        {
            title: 'K bolya vai k bolya',
            image: 'https://i.ytimg.com/vi/yWW-oNXg0Jo/maxresdefault.jpg',
        },
        {
            title: 'Ek Zamana Tha',
            image: 'https://indianmemetemplates.com/wp-content/uploads/beta-ek-zamana-tha-jab-hum-bhi-gareeb-hua-karte-the.jpg',
        },
    ]

    return (
        <div className='flex flex-col px-5 '>

            <FileDrop
                onDrop={handleDrop}
                text="Drop and drop or click to upload draft."
                icon={faFile}
            />

            <div className='flex flex-wrap mt-5'>

                {availableDrafts.map((draft, index) => (
                    <div className='w-1/2 mb-4 p-2 text-center hover:cursor-pointer hover:border border-dotted border-gray-600'>
                        <img src={draft.image} height='100px' width='100%' className='margin-auto' />
                        <p className='text-gray-300 text-xs mt-2'>{draft.title}</p>
                    </div>
                ))}

            </div>

        </div>
    );
}