import React, { useState } from 'react';
import TextTransformOptions from '../Tools/TextTransformOptions';

import { CanvasOptions } from '../Tools/CanvasOptions';



export default function RightSideBar({ editor }) {
    const [activeObject, setActiveObject] = useState('CANVAS');

    if (!editor) return <></>

    editor?.on('selection:created', function () {
        setActiveObject(editor.getActiveObject());
    })

    editor?.on('selection:updated', function () {
        setActiveObject(editor.getActiveObject());
    });

    editor?.on('selection:cleared', () => {
        setActiveObject('CANVAS');
    })

    return (
        <aside className="bg-slate-800 md:w-80 hidden md:block px-3 h-screen overflow-scroll pb-48">
            <div className='flex text-gray-200 border-b border-slate-700 py-2 mb-3'>
                <div className='m-auto uppercase text-sm '>{activeObject?.type || activeObject}</div>
            </div>

            {activeObject?.type === 'textbox' && <TextTransformOptions activeObject={activeObject} editor={editor} />}
            {activeObject === 'CANVAS' && <CanvasOptions />}
        </aside>
    )
}