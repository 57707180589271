import React, { useEffect, useCallback, useState, useRef } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import FontPicker from "font-picker-react";
import { faAlignCenter, faAlignLeft, faAlignRight, faBold, faItalic, faUnderline } from '@fortawesome/free-solid-svg-icons';

import CreatableSelect from 'react-select/creatable';

import { Title } from '../Utilities/Title';
import { ButtonSwitch } from '../Utilities/ButtonSwitch';

import { SketchPicker } from 'react-color';


const google_api_key = 'AIzaSyAymwM74S7H1eUg9aTbpRhvYSPj-MwQdq4';

export default function TextTransformOptions({ activeObject, editor }) {
    const [fontLimit] = useState('150');
    const [fontFamily, setFontFamily] = useState(activeObject.fontFamily);
    // const [fontSize, setFontSize] = useState(activeObject.fontSize);
    const [fontSize, setFontSize] = useState('100');

    const [textAlign, setTextAlign] = useState(activeObject.textAlign);
    const [textOutline, setTextOutline] = useState(activeObject.stroke);

    const [fontBold, setFontBold] = useState(activeObject.fontWeight === 'bold');
    const [fontItalic, setFontItalic] = useState(activeObject.fontStyle === 'italic');
    const [fontColor, setFontColor] = useState(activeObject.fill);
    const [textBackgroundColor, setTextBackgroundColor] = useState(activeObject.backgroundColor);
    const [fontUnderline, setFontUnderline] = useState(activeObject.underline);

    const loadAndUseFont = useCallback((font) => {
        var myfont = new FontFaceObserver(font);
        myfont.load()
            .then(function () {
                // when font is loaded, use it.
                editor.getActiveObjects().map(obj => obj?.set("fontFamily", font));
                editor.requestRenderAll();

                // change font family of Font Picker too
                document.querySelector('.dropdown-button').style.fontFamily = font;
            }).catch(function (e) {
                console.error(e)
            });
    }, [editor]);

    const useDidMountEffect = (func, deps) => {
        const didMount = useRef(false);

        useEffect(() => {
            if (didMount.current) func();
            else didMount.current = true;
        }, deps);
    }

    useDidMountEffect(() => {
        loadAndUseFont(fontFamily);
    }, [fontFamily]);

    useDidMountEffect(() => {
        editor.getActiveObjects().map(obj => obj?.set("fontSize", fontSize));
        editor.requestRenderAll();
    }, [fontSize]);

    useDidMountEffect(() => {
        editor.getActiveObjects().map(obj => obj?.set("fontWeight", fontBold ? 'bold' : 'normal'));
        editor.requestRenderAll();
    }, [fontBold]);

    useDidMountEffect(() => {
        editor.getActiveObjects().map(obj => obj?.set("fontStyle", fontItalic ? 'italic' : 'normal'));
        editor.requestRenderAll();
    }, [fontItalic]);

    useDidMountEffect(() => {
        editor.getActiveObjects().map(obj => obj?.set("fill", fontColor));
        editor.requestRenderAll();
    }, [fontColor]);

    useDidMountEffect(() => {
        editor.getActiveObjects().map(obj => obj?.set("underline", fontUnderline));
        editor.requestRenderAll();
    }, [fontUnderline]);

    useDidMountEffect(() => {
        editor.getActiveObjects().map(obj => obj?.set("backgroundColor", textBackgroundColor));
        editor.requestRenderAll();
    }, [textBackgroundColor]);

    useDidMountEffect(() => {
        editor.getActiveObjects().map(obj => obj?.set("textAlign", textAlign));
        editor.requestRenderAll();
    }, [textAlign]);

    useDidMountEffect(() => {
        editor.getActiveObjects().map(obj => obj?.set("stroke", textOutline));
        editor.requestRenderAll();
    }, [textOutline]);

    useEffect(() => {
        setFontFamily(activeObject.fontFamily);
    }, [activeObject]);

    return (
        <div>
            <Title value="Font" />

            <div className="flex flex-row items-center justify-between">

                <FontPicker
                    apiKey={google_api_key}
                    activeFontFamily={fontFamily}
                    onChange={(nextFont) =>
                        setFontFamily(nextFont.family)
                    }
                    limit={fontLimit}
                />

                <CreatableSelect
                    defaultOptions
                    options={
                        [14, 18, 24, 36, 48, 64, 72, 96, 100, 125, 130]
                            .map((i) => ({ 'label': i, 'value': i }))
                    }
                    getOptionLabel={e => e.label}
                    getOptionValue={e => e.value}
                    placeholder="Size"
                    className='react-select-container'
                    classNamePrefix="react-select"
                    onBlur={e => e.target.blur()}
                    value={{ label: fontSize }}
                    onChange={(nextSize) => setFontSize(nextSize.value)}
                    noOptionsMessage={() => <div className="text-gray-400 text-sm">No results found </div>}
                />

            </div>

            <div className='flex flex-row my-3 mb-10 items-center justify-between'>
                <ButtonSwitch icon={faBold} state={fontBold} onClick={() => setFontBold(!fontBold)} />
                <ButtonSwitch icon={faItalic} state={fontItalic} onClick={() => setFontItalic(!fontItalic)} />
                <ButtonSwitch icon={faUnderline} state={fontUnderline} onClick={() => setFontUnderline(!fontUnderline)} />
                <ButtonSwitch icon={faAlignLeft} state={textAlign === 'left'} onClick={() => setTextAlign('left')} />
                <ButtonSwitch icon={faAlignCenter} state={textAlign === 'center'} onClick={() => setTextAlign('center')} />
                <ButtonSwitch icon={faAlignRight} state={textAlign === 'right'} onClick={() => setTextAlign('right')} />
            </div>

            <Title value="Text Color" />
            <div className='flex flex-row my-3 mb-10 items-center justify-between'>
                <SketchPicker
                    color={fontColor}
                    onChangeComplete={color => setFontColor(color.hex)}
                />
            </div>

            <Title value="Text Outline" />
            <div className='flex flex-row my-3 mb-10 items-center justify-evenly'>
                <ButtonSwitch value="None" state={textOutline === ''} onClick={() => setTextOutline('')} />
                <ButtonSwitch value="Light" state={textOutline === 'white'} onClick={() => setTextOutline('white')} className="outline-text" />
                <ButtonSwitch value="Dark" state={textOutline === 'black'} onClick={() => setTextOutline('black')} />
            </div>

            <Title value="Text Background Color" />
            <div className='flex flex-row my-3 items-center justify-evenly'>
                <SketchPicker
                    color={textBackgroundColor}
                    onChangeComplete={color => setTextBackgroundColor(color.hex)}
                />
            </div>
        </div>
    )
}