import React from 'react';
import { addTextToCanvas } from '../../utils/text';

export default function TextLeftBar({ editor }) {

    return (
        <div className='flex flex-col px-5'>
            <button className="button bg-blue-600 py-1 rounded" onClick={e => addTextToCanvas(editor)}>
                Add Text
            </button>
        </div>
    )
}